<script>
import constants from "@/utils/constants";

export default {
  name: "CreateSupportTicket",
  data() {
    return {
      valid: true,
      ticket: {
        first_name: "",
        last_name: "",
        email: "",
        category: "",
        subject: "",
        description: "",
        attachments: []
      },
      qportalModules: constants.QportalModules,
    }
  },
  methods: {
    onFilePicked(e) {
      this.ticket.attachments = [...this.ticket.attachments, ...e.target.files]
    },
    sliceImage(index) {
      if (Array.isArray(this.ticket.attachments) && index >= 0 && index < this.ticket.attachments.length) {
        // Use Array.slice to extract the image at the specified index
        this.ticket.attachments.splice(index, 1);
      }
    },
    submitTicket() {
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return false;
      }
      this.showLoader("Submitting")
      const formData = new FormData();
      Object.keys(this.ticket).forEach(key => {
        if (key === 'attachments') {
          if (this.ticket.attachments.length) {
            this.ticket.attachments.forEach((file, index) => {
              formData.append(`attachments[${index}]`, file)
            })
          }
        } else {
          formData.append(key, this.ticket[key])
        }
      })
      this.$http.post('/venues/support/customer-support', formData)
          .then(res => {
            if (res.status === 200) {
              this.$router.push({name: 'CustomerSupport'})
            }
          })
          .catch(error => {
            this.errorChecker(error)
          })
          .finally(() => {
            this.hideLoader()
          })
    }
  },
  mounted() {
    const user = this.$store.getters['userInfo']
    this.ticket.first_name = user.first_name;
    this.ticket.last_name = user.last_name;
    this.ticket.email = user.email;
  }
}
</script>

<template>
  <v-container>
    <div>
      <v-btn class="back-button" elevation="0">
        <router-link :to="{name:'CustomerSupport'}">
          <v-icon size="18">mdi-arrow-left</v-icon>
          Back
        </router-link>
      </v-btn>
      <div class="d-flex justify-center mx-auto" style="max-width: 800px">
        <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="submitTicket">
          <h3 class="text-center">
            We're here to help you
          </h3>
          <p class="text-center">
            Share the most important info with us
          </p>
          <v-divider/>
          <br>
          <div class="row">
            <div class="col-12 col-lg-6">
              <v-text-field
                  v-model="ticket.first_name"
                  :rules="[(v) => !!v || 'First Name is required']"
                  background-color="#fff"
                  label="First Name*"
                  outlined
              ></v-text-field>
            </div>
            <div class="col-12 col-lg-6">
              <v-text-field
                  v-model="ticket.last_name"
                  :rules="[(v) => !!v || 'Last Name is required']"
                  background-color="#fff"
                  label="Last Name*"
                  outlined
              ></v-text-field>
            </div>
            <div class="col-12 col-lg-6">
              <v-text-field
                  v-model="ticket.email"
                  :rules="[
                    (v) => !!v || 'Email is required',
                    (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                  ]"
                  background-color="#fff"
                  label="Email*"
                  outlined
              ></v-text-field>
            </div>
            <div class="col-12 col-lg-6">
              <v-select
                  v-model="ticket.category"
                  :items="qportalModules"
                  :label="`Category*`"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :rules="[(v) => !!v || 'Category is required']"
                  background-color="#fff"
                  outlined
                  required
              ></v-select>
            </div>
            <div class="col-12">
              <v-text-field
                  v-model="ticket.subject"
                  :rules="[(v) => !!v || 'Subject is required']"
                  background-color="#fff"
                  label="Subject*"
                  outlined
              ></v-text-field>
            </div>
            <div class="col-12">
              <v-textarea
                  v-model="ticket.description"
                  background-color="#fff"
                  label="Description"
                  name="description"
                  outlined
                  rows="6"
              ></v-textarea>
            </div>
            <div class="col-12">
              <label for="attachments" role="button">
                Attach Files
                <input
                    id="attachments"
                    accept="image/*"
                    multiple
                    style="display: none"
                    type="file"
                    @change="onFilePicked"
                />
                <span class="attachment-box">
                Add files here
              </span>
              </label>

              <br>
              <div v-for="(file,index) in ticket.attachments" :key="file.name" class="d-flex justify-start">
                <span style="width: 300px">
                  {{ file.name.slice(0, 50) }}
                </span>
                <v-btn class="bg-transparent" elevation="0" @click="sliceImage(index)">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <div class="d-flex justify-end">
            <v-btn type="submit">
              Submit Request
            </v-btn>
          </div>
        </v-form>
      </div>
    </div>
  </v-container>
</template>

<style lang="scss" scoped>
.back-button {
  background: transparent !important;
  font-weight: 500;

  &, * {
    color: #112A46 !important;
  }
}

.bg-transparent {
  background: transparent !important;
}

.attachment-box {
  border-radius: 0.375rem;
  border: 1px dashed #CECECE;
  background: #F5F8FA;
  width: 100%;
  min-height: 12.8125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}
</style>